const _temp0 = require("url:./50-black.mp4");
const _temp1 = require("url:./50-red.mp4");
const _temp2 = require("url:./billig-black.mp4");
const _temp3 = require("url:./billig-red.mp4");
const _temp4 = require("url:./percent-black.mp4");
const _temp5 = require("url:./percent.mp4");
const _temp6 = require("url:./sale-black.mp4");
const _temp7 = require("url:./sale-red.mp4");
const _temp8 = require("url:./sale-white.mp4");
module.exports = {
  "50-black": _temp0,
  "50-red": _temp1,
  "billig-black": _temp2,
  "billig-red": _temp3,
  "percent-black": _temp4,
  "percent": _temp5,
  "sale-black": _temp6,
  "sale-red": _temp7,
  "sale-white": _temp8
}