import PropTypes from "prop-types";
import classNames from "classnames";

export const Play = (props) => {
  return (
    <svg
      className={classNames({
        [props.className]: props.className,
        "cursor-pointer": true,
      })}
      onClick={props.onClick}
      width="191"
      height="191"
      viewBox="0 0 191 191"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0L0 190.844H190.844V0H0Z" fill="#0E0E10" />
      <path
        d="M60.408 157.27L143.729 96.221L60.408 34.3707L60.408 157.27Z"
        fill="#ECECE7"
      />
    </svg>
  );
};

Play.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};
