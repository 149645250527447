{
  "items": [
    {
      "code": "SALE Shokoreme",
      "image": "choco",
      "headline": "Billig und geschmacklos",
      "subline": "Palmfett aus Südostasien macht Schokocreme streichfähig.",
      "question": "Warum sollten wir kein Palmfett essen?",
      "A": "Um Ölpalmen für Deutschland zu pflanzen, wird jedes Jahr mehr Regenwald abgeholzt.",
      "B": "Öle aus heimischen Pflanzen sind gesünder und nachhaltiger.",
      "correct": ["A", "B"],
      "description_a": "Wie in den Zeiten des Kolonialismus zerstören wir Regenwaldflächen, um für Europa billige Lebensmittel zu produzieren. Viele Pflanzen- und Tierarten, wie der Orang-Utan, sterben dadurch für immer aus. ",
      "description_b": "Raps oder Sonnenblumen wachsen auch in Deutschland. Ihr Öl ist gesünder und muss auch nicht um die halbe Erde transportiert werden. Das schont Klima und Regenwälder.",
      "background": "bg-freiburg-red",
      "background_text": "billig-black",
      "background_quiz": "choco"
    },
    {
      "code": "SALE Banane",
      "image": "banana",
      "headline": "(Neo-) koloniale Massenware",
      "subline": "In der Kolonialzeit noch unvorstellbar! Jede_r Deutsche isst 10 kg Bananen im Jahr.",
      "question": "Was kannst du tun, um Ausbeutung von Arbeiter_innen und Umwelt in Südamerika zu verhindern?",
      "A": "Nur noch Bananen aus der EU kaufen.",
      "B": "Biozertifizierte und fair gehandelte Bananen kaufen.",
      "correct": ["A","B"],
      "description_a": "Eine gute Idee! Das garantiert faire Preise, bessere Arbeitsbedingungen und einen Bananenanbau ohne Insektengifte. Der Transport bleibt jedoch auch bei diesen Bananen ein Problem für das Klima.",
      "description_b": "Aber Bananen wachsen in der EU leider nur in Gebieten, in denen das Wasser knapp ist. Bananenanbau ist auch dort ein Problem für die Umwelt. Noch besser also: weniger Bananen essen und dafür bewusst genießen.",
      "background": "bg-freiburg-red",
      "background_text": "billig-black",
      "background_quiz": "banana"
    },
    {
      "code": "SALE Zuker",
      "image": "sugar",
      "headline": "Zucker aus den Tropen",
      "subline": "Vor der „Erfindung“ des Rübenzuckers war Rohrzucker eine teure Kolonialware.",
      "question": "Ist Rohrzucker besser als Rübenzucker?",
      "A": "Ja, er ist gesünder und schmeckt besser.",
      "B": "Nein, beide sind gleich ungesund.",
      "correct": ["B"],
      "description_a": "Chemisch gibt es keinen Unterschied zum Rübenzucker. Beide sind schlecht für unsere Gesundheit. Für den Zuckerrohranbau in den Tropen werden noch heute Regenwälder gerodet und Arbeiter_innen ausgebeutet.",
      "description_b": "Wer trotzdem auf Zucker nicht verzichten möchte, kauft Rübenzucker aus Bio-Anbau. Für ihn werden keine Regenwälder gerodet und er wächst bei uns direkt vor der Haustür.",
      "background": "bg-freiburg-red",
      "background_text": "50-red",
      "background_quiz": "sugar"
    },
    {
      "code": "SALE Handy",
      "image": "phone",
      "headline": "Blutiges Geschäft",
      "subline": "Der Rohstoffabbau für Handys finanziert Kriege und nutzt Kinderarbeit in Afrika.",
      "question": "Wie kann ich Kinderarbeit und Umweltzerstörung für Handys stoppen?",
      "A": "Ich lasse mein Handy reparieren oder recyceln.",
      "B": "Ich kaufe nur teure Smartphones.",
      "correct": ["A"],
      "description_a": "Eine gute Idee! Durchschnittlich landet ein Handy schon nach 18 Monaten auf dem Müll, obwohl es noch funktioniert. Reparieren ist oft billiger als ein neues Handy und schont Umwelt.",
      "description_b": "Auch im teuersten Handy stecken Rohstoffe, die meist in Afrika von Kindern unter lebensgefährlichen Bedingungen abgebaut werden. Da hilft nur eines: weniger Handys kaufen, dafür länger benutzen.",
      "background": "bg-freiburg-white",
      "background_text": "percent",
      "background_quiz": "phone"
    },
    {
      "code": "SALE Shokolade",
      "image": "kakao",
      "headline": "Genuss und Ausbeutung",
      "subline": "Durchschnittlich landen nur 7% des Schokoladenpreises bei den Kakaobauern vor Ort.",
      "question": "Bis wann soll(te) Kinderarbeit auf Kakaoplantagen abgeschafft werden?",
      "A": "Bis 2025.",
      "B": "Bis 2005.",
      "correct": ["B"],
      "description_a": "Eine Selbstverpflichtung (2001) versprach die Abschaffung von Kinderarbeit im Kakaoanbau bis 2005. Erfolglos! 2021 strebten die Hersteller nur noch eine Reduzierung der Kinderarbeit um 70 % an.",
      "description_b": "Das Ziel ist bis heute nicht erreicht. Umso wichtiger ist der Kauf fair gehandelter Schokolade, möglichst aus Bioanbau. Kinderarbeit ist hier verboten!",
      "background": "bg-freiburg-white",
      "background_text": "sale-red",
      "background_quiz": "kakao"
    },
    {
      "code": "SALE Kaffee",
      "image": "coffee",
      "headline": "Bittere Wahrheit",
      "subline": "Kaffee war eine der ersten gewinnbringenden Kolonialwaren.",
      "question": "Warum wird der wachsende Kaffeekonsum heute zum Problem?",
      "A": "Für die Produktion einer Tasse Kaffee werden 140 Liter Wasser benötigt.",
      "B": "Die Anbauflächen für die Zukunft sind begrenzt.",
      "correct": ["A"],
      "description_a": "Auch in den Tropen wird das Wasser in den Anbaugebieten durch den Klimawandel knapp. Das Wasser für die Kaffeeplantagen fehlt dann in der Natur und der übrigen Landwirtschaft.",
      "description_b": "Kaffee mag es feucht und kühl. Deswegen werden oft die letzten Bergregenwälder für Kaffeeplantagen gerodet. Tausende Tier- und Pflanzenarten sterben für immer aus.",
      "background": "bg-freiburg-red",
      "background_text": "50-red",
      "background_quiz": "coffee"
    },
    {
      "code": "SALE Hhnhen",
      "image": "chicken",
      "headline": "Edles Fleisch für kleines Geld",
      "subline": "Ein Hähnchenbrustfilet gibt’s beim Discounter schon ab 3 Euro.",
      "question": "Was passiert mit dem Rest vom Huhn?",
      "A": "Flügel, Innereien und Schlegel werden an Restaurants und Kantinen verkauft.",
      "B": "Die Hühnerreste werden tiefgefroren in viele Länder Afrikas exportiert.",
      "correct": ["B"],
      "description_a": "Kund_innen bevorzugen das zarte Brustfleisch. Flügel und Innereien werden meist nach Afrika exportiert. Dort sind sie jedoch billiger als afrikanische Hühner. Die heimischen Bauern verdienen kein Geld mehr.",
      "description_b": "Dort ist europäisches Hühnerfleisch dann billiger als Hühnerfleisch von heimischen Bauernhöfen. Viele Bauern müssen ihre Höfe aufgeben. Einige Länder beginnen sich mit Zöllen gegen das Billigfleisch zu wehren.",
      "background": "bg-freiburg-white",
      "background_text": "percent",
      "background_quiz": "chicken"
    },
    {
      "code": "SALE T-shirt",
      "image": "wolle",
      "headline": "Fast Fashion",
      "subline": "Deutsche kaufen durchschnittlich 60 Kleidungsstücke pro Jahr.",
      "question": "Warum ist Kleidung aus Baumwolle so billig?",
      "A": "Der Anbau ist voll automatisiert und Baumwollpflanzen brauchen wenig Pflege.",
      "B": "Schlechte Löhne und Arbeitsbedingungen in Anbau und Verarbeitung werden bewusst ausgenutzt.",
      "correct": ["B"],
      "description_a": "Auch heute erfolgen noch viele Arbeitsschritte von Hand. Schlechte Löhne und Kinderarbeit machen die Baumwolle trotzdem billig. Nur eines hilft: weniger kaufen, aber dafür auf fair gehandelte Biobaumwolle setzen.",
      "description_b": "Schlechte Löhne und Kinderarbeit machen Baumwolle zum billigen Rohstoff. Nur eines hilft: weniger konsumieren, aber dafür bewusst fair gehandelte Biobaumwolle kaufen.",
      "background": "bg-freiburg-red",
      "background_text": "percent-black",
      "background_quiz": "wolle"
    },
    {
      "code": "SALE Thunfish",
      "image": "fisch",
      "headline": "Ein Meer ohne Fische",
      "subline": "Seit der Kolonialzeit sind 90 % der Großfische aus den Meeren verschwunden.",
      "question": "Was hat Dosenthunfisch mit (Neo-)kolonialismus zu tun?",
      "A": "Europäische Fischereikonzerne zerstören die Küstenfischerei in vielen Teilen Afrikas.",
      "B": "Das Dosenblech stammt aus ehemaligen deutschen Kolonien in Afrika.",
      "correct": ["A"],
      "description_a": "Immer effektivere Fangmethoden zerstören die Fischbestände an Afrikas Küsten. Den Fischern vor Ort bleibt zu wenig Fisch, um zu Überleben. Deshalb: informieren und nur Fisch aus zertifiziert nachhaltiger Fischerei kaufen.",
      "description_b": "Der Doseninhalt stammt allzu oft aus Gewässern vor der Küste Afrikas. Dort wird er im industriellen Maßstab von europäischen Schiffen gefangen. Den Fischern vor Ort bleibt zu wenig, um zu Überleben.",
      "background": "bg-freiburg-white",
      "background_text": "billig-red",
      "background_quiz": "fisch"
    },
    {
      "code": "SALE Kokosl",
      "image": "kokos",
      "headline": "Schmackhaft und gesund?",
      "subline": "Kokosfett ist wieder in Mode. Auch schon zu Kolonialzeiten war es beliebt.",
      "question": "Ist Kokosfett eine nachhaltige und gesunde Alternative zum Palmöl?",
      "A": "Ja, die Kokosplantagen in Südostasien verschlingen nicht so viel Regenwald wie die Ölpalmplantagen.",
      "B": "Nein, Kokosfett gehört zu den ungesündesten Fetten. Auch für Kokospalmen wird Regenwald gerodet.",
      "correct": ["B"],
      "description_a": "Schon heute können bestehende Plantagen in den Tropen die steigende Nachfrage in Europa nicht mehr decken. Immer mehr Regenwald wird gerodet. Heimische Pflanzenöle aus Deutschland sind nachhaltiger und gesünder.",
      "description_b": "Kokosfett enthält besonders viel gesättigte Fettsäuren. Für heimische Pflanzenöle aus Deutschland muss kein Regenwald gerodet werden. Und gesünder sind sie auch.",
      "background": "bg-freiburg-red",
      "background_text": "sale-white",
      "background_quiz": "kokos"
    },
    {
      "code": "SALE Shinken",
      "image": "schinken",
      "headline": "Regional oder Kolonial?",
      "subline": "Soja ist heute der wichtigste Bestandteil von Futter in der Schweinemast.",
      "question": "Woher stammt das Schweinefutter für diesen Schinken?",
      "A": "Argentinien und Brasilien",
      "B": "Schwarzwald",
      "correct": ["A"],
      "description_a": "Die größten Sojaproduzenten sind die USA, Argentinien und Brasilien. Dort werden immer mehr Wälder gerodet, um billiges Tierfutter für Europa anzubauen.",
      "description_b": "Auch bei der Schweinemast im Schwarzwald wird Soja aus Brasilien und Argentinien verfüttert. Regenwald wird zerstört und Feldarbeiter_innen ausgebeutet. Aber es gibt biozertifizierte Alternativen.",
      "background": "bg-freiburg-white",
      "background_text": "50-black",
      "background_quiz": "schinken"
    },
    {
      "code": "SALE ashew",
      "image": "nuss",
      "headline": "Einmal um die ganze Welt",
      "subline": "Weite Reise und Pestizide machen die Cashewnuss zum Problem für Mensch und Natur.",
      "question": "Woher kommen die Cashewnüsse im Supermarkt?",
      "A": "Brasilien",
      "B": "Vietnam",
      "correct": ["B"],
      "description_a": "Der Cashewbaum stammt eigentlich aus Brasilien. Die Kolonialmächte gründeten Plantagen in Afrika und Asien. Die Supermarktnuss kommt heute oft aus Westafrika, wird in Vietnam verarbeitet und dann nach Europa verschifft.",
      "description_b": "Vietnam ist mit Indien eines der wichtigsten Anbau- und Verarbeitungszentren. Die Rohnüsse werden oft aus Afrika dorthin verschifft und gelangen am Ende nach Europa. Das ist schlecht fürs Klima!",
      "background": "bg-freiburg-red",
      "background_text": "sale-black",
      "background_quiz": "nuss"
    }
  ]
}