import PropTypes from "prop-types";

export const WrongHand = (props) => {
  return (
    <svg
      width="774"
      height="810"
      viewBox="0 0 774 810"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M191.152 461.4L368.131 53.3854L457.884 88.6728C510.097 109.117 552.501 148.75 576.351 199.398L714.739 253.807C714.739 253.807 738.789 305.884 701.259 397.635C663.566 489.884 603.621 512.504 603.621 512.504L500.119 474.353C490.28 480.787 480.112 486.707 469.657 492.09C457.271 498.314 404.827 522.166 390.337 557.672C375.647 594.024 349.327 721.976 311.601 716.817C226.731 705.173 257.195 613.615 278.28 553.498C281.957 543.184 282.29 531.971 279.23 521.443C276.17 510.915 269.873 501.605 261.228 494.828C249.179 485.906 235.933 478.72 221.882 473.482C216.689 471.44 213.931 469.86 213.253 468.636L191.152 461.4Z"
        fill="#46877F"
        stroke="#0E0E10"
        strokeWidth="12"
        strokeMiterlimit="10"
      />
      <path
        d="M502.081 473.407C538.342 448.712 566.281 413.678 582.257 372.869L502.081 473.407Z"
        fill="#46877F"
      />
      <path
        d="M502.081 473.407C538.342 448.712 566.281 413.678 582.257 372.869"
        stroke="#0E0E10"
        strokeWidth="12"
        strokeMiterlimit="10"
      />
      <path
        d="M234.293 60.4578L234.282 60.4864C223.056 89.0394 237.182 121.318 265.833 132.582L327.467 156.814C356.118 168.079 388.445 154.064 399.671 125.511L399.682 125.482C410.908 96.9294 396.782 64.651 368.131 53.3865L306.497 29.1545C277.846 17.8899 245.519 31.9048 234.293 60.4578Z"
        fill="#46877F"
        stroke="#0E0E10"
        strokeWidth="12"
        strokeMiterlimit="10"
      />
      <path
        d="M173.717 156.055L173.706 156.084C162.48 184.637 176.606 216.915 205.257 228.18L305.311 267.517C333.962 278.782 366.289 264.767 377.515 236.214L377.526 236.185C388.752 207.632 374.626 175.354 345.974 164.089L245.921 124.752C217.269 113.488 184.943 127.502 173.717 156.055Z"
        fill="#46877F"
        stroke="#0E0E10"
        strokeWidth="12"
        strokeMiterlimit="10"
      />
      <path
        d="M133.064 259.455L133.053 259.484C121.827 288.037 135.952 320.316 164.604 331.58L279.435 376.727C308.086 387.992 340.413 373.977 351.639 345.424L351.65 345.395C362.876 316.842 348.75 284.564 320.099 273.299L205.268 228.152C176.616 216.888 144.29 230.902 133.064 259.455Z"
        fill="#46877F"
        stroke="#0E0E10"
        strokeWidth="12"
        strokeMiterlimit="10"
      />
      <path
        d="M92.3714 362.873L92.3601 362.902C81.1342 391.455 95.2601 423.733 123.911 434.998L258.483 487.906C287.134 499.171 319.461 485.156 330.687 456.603L330.698 456.574C341.924 428.021 327.798 395.743 299.147 384.478L164.575 331.57C135.924 320.305 103.597 334.32 92.3714 362.873Z"
        fill="#46877F"
        stroke="#0E0E10"
        strokeWidth="12"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

WrongHand.propTypes = {
  className: PropTypes.string,
};
