import { useNavigate } from "react-router-dom";

export const ClosePage = () => {
  const navigate = useNavigate();

  return (
    <div className="flex h-full min-h-screen flex-col items-center justify-center bg-black">
      <h1 className="mx-20 text-center font-Monument text-9xl font-bold text-freiburg-white">
        Möchtest du den <br /> SUPER&nbsp;SALE&nbsp;STORE <br /> wirklich
        verlassen?
      </h1>
      <div className="mt-16 flex flex-row space-x-14">
        <button
          onClick={() => navigate("/")}
          className="min-w-[21rem] bg-freiburg-red px-6 py-6 py-10 font-Monument text-7xl font-bold text-freiburg-white"
        >
          JA
        </button>
        <button
          onClick={() => navigate(-1)}
          className="min-w-[21rem] bg-freiburg-white px-6 py-6 font-Monument text-7xl font-bold text-freiburg-red"
        >
          NEIN
        </button>
      </div>
    </div>
  );
};
