import { Route, Routes, useNavigate } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch, useSelector } from "react-redux";

import { Start } from "./pages/start/start";
import { Presentation } from "./game/presentation";
import { Cart } from "./game/cart";
import { Quiz } from "./game/quiz";
import { Fazit } from "./pages/fazit";
import { ClosePage } from "./pages/close";
import { Idle } from "./pages/idle";
import { setFinal } from "./game/finalSlice";
import { useEffect } from "react";

export const App = () => {
  const items = useSelector((state) => state.items.value);
  const final = useSelector((state) => state.final.value);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onIdle = () => {
    navigate("/idle");
  };

  const idleTimer = useIdleTimer({ onIdle, timeout: 1000 * 1 * 60 });

  const handler = (e) => {
    if (final) {
      e.stopPropagation();
      e.preventDefault();
      navigate("/cart");
    }
  };

  useEffect(() => {
    console.log(items.filter((item) => item.used).length);
    if (items.filter((item) => item.used).length === 12) {
      dispatch(setFinal(true));
      document.addEventListener("click", handler);
      dispatch(setFinal(true));
      setTimeout(() => {
        navigate("/cart");
      }, 1000 * 30);
    } else {
      dispatch(setFinal(false));
    }
  }, [items]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Start />} />
        <Route path="/spielen/present" element={<Presentation />} />
        <Route path="/spielen/quiz" element={<Quiz />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/fazit" element={<Fazit />} />
        <Route path="/exit" element={<ClosePage />} />
        <Route path="/idle" element={<Idle />} />
      </Routes>
    </>
  );
};
