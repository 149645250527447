import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { setByValue } from "../game/itemsSlice";
import content from "./../content.json";
import { useDispatch } from "react-redux";
import { setFinal } from "../game/finalSlice";

export const Idle = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [timer, setTimer] = useState(10);

  useEffect(() => {
    const timerId = setInterval(() => {
      console.log(timer);
      if (timer > 0) {
        setTimer(timer - 1);
      } else {
        dispatch(
          setByValue(content.items.map((item) => ({ ...item, used: false })))
        );
        dispatch(setFinal(false));
        navigate("/");
      }
    }, 1000);

    return () => clearInterval(timerId);
  });

  return (
    <div className="flex h-full min-h-screen flex-col items-center justify-center bg-black">
      <h1 className="mx-20 mb-20 text-center text-9xl font-bold text-freiburg-white">
        Dein Einkaufswagen wird geleert in ...
      </h1>
      <span className="font-Monument text-[300px] leading-tight text-freiburg-red">
        {timer}
      </span>
    </div>
  );
};
