import { createSlice } from "@reduxjs/toolkit";

import content from "./../content.json";

export const itemsSlice = createSlice({
  name: "items",
  initialState: {
    value: content.items.map((item) => ({ ...item, used: false })),
  },
  reducers: {
    setByValue: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setByValue } = itemsSlice.actions;

export default itemsSlice.reducer;
