import { createSlice } from "@reduxjs/toolkit";

export const answerSlice = createSlice({
  name: "answer",
  initialState: {
    value: null,
  },
  reducers: {
    setAnswer: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAnswer } = answerSlice.actions;

export default answerSlice.reducer;
