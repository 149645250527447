import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import { Close } from "./components/close";
import { Cart } from "./components/cart";
import { Play } from "./components/play";
import { Back } from "./components/back";

export const UI = (props) => {
  const navigate = useNavigate();

  return (
    <div className="absolute right-0 top-0 bottom-0 z-30 flex flex-col">
      {props.items.includes("close") ? (
        <Close onClick={() => navigate("/exit")} />
      ) : null}
      {props.items.includes("cart") ? (
        <Cart onClick={() => navigate("/cart")} />
      ) : null}
      {props.items.includes("play") ? (
        <Play onClick={() => navigate("/spielen/quiz")} className="mt-auto" />
      ) : null}

      {props.items.includes("back") ? (
        <Back
          onClick={() => navigate("/spielen/present")}
          className="mt-auto"
        />
      ) : null}
    </div>
  );
};

UI.propTypes = {
  items: PropTypes.array.isRequired,
};
