import classNames from "classnames";
import PropTypes from "prop-types";

export const Back = (props) => {
  return (
    <svg
      className={classNames({
        [props.className]: props.className,
        "cursor-pointer": true,
      })}
      onClick={props.onClick}
      width="191"
      height="191"
      viewBox="0 0 191 191"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 -0.000106812L0 190.844H190.844V-0.000106812H0Z"
        fill="#ECECE7"
      />
      <path
        d="M75.3853 116.304L21.7782 75.9174L75.3853 35.0002V116.304Z"
        fill="#0E0E10"
      />
      <path
        d="M68.4059 144.001H121.433C131.696 143.243 152.222 135.131 152.222 108.75C152.222 82.3689 129.795 75.3946 118.582 75.2051H52.1558"
        stroke="#0E0E10"
        strokeWidth="30"
      />
    </svg>
  );
};

Back.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};
