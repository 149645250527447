import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { setByValue } from "../../game/currentItemSlice";

import StartVideo from "url: ../../../static/Animation_Scan.mp4";

export const Start = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const items = useSelector((state) => state.items.value);
  const [selectVisible, setSelectVisible] = useState(false);
  const [codeUsed, setCodeUsed] = useState(false);

  let code = "";

  const handleQrScannerInput = (event) => {
    console.log("event.keyCode", event.keyCode);
    console.log("event.key", event.key);

    // No Ctrl+Shift+C and no Enter and no F11.
    if (
      event.keyCode !== 13 &&
      event.keyCode !== 17 &&
      event.keyCode !== 16 &&
      event.keyCode !== 67 &&
      event.keyCode !== 122
    ) {
      event.preventDefault();
      code = code.toString() + event.key.toString();
    }

    // Enter at the end of input.
    if (event.keyCode === 13) {
      event.preventDefault();

      const item = items.filter((i) => i.code === code);

      console.log(code);
      console.log(item);
      setCodeUsed(code);

      if (item.length > 0) {
        dispatch(setByValue(item[0]));
        navigate("/spielen/present");
      } else {
        code = "";
      }
    }

    if (event.keyCode === 27) {
      setSelectVisible(!selectVisible);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleQrScannerInput);

    // Return a clean-up function.
    return () => {
      window.removeEventListener("keydown", handleQrScannerInput);
    };
  }, []);

  return (
    <>
      {selectVisible ? (
        <div className="absolute top-1/2 left-1/2 z-50 -translate-x-1/2 -translate-y-1/2 transform border-8 border-freiburg-red bg-freiburg-white">
          <select
            onChange={(event) => {
              dispatch(
                setByValue(
                  items.filter((item) => item.image === event.target.value)[0]
                )
              );
              navigate("/spielen/present");
            }}
          >
            <option>Select an item</option>
            {items.map((option) => (
              <option key={option.image} value={option.image}>
                {option.image}
              </option>
            ))}
          </select>
        </div>
      ) : null}

      <div className="relative flex h-screen items-center justify-center overflow-hidden">
        <div className="absolute z-50 text-7xl font-bold">{codeUsed}</div>
        <video
          autoPlay
          loop
          muted
          className="absolute z-10 h-full w-full object-cover"
        >
          <source src={StartVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </>
  );
};
