import classNames from "classnames";
import PropTypes from "prop-types";

export const Cart = (props) => {
  return (
    <svg
      className={classNames({
        [props.className]: props.className,
        "cursor-pointer": true,
      })}
      onClick={props.onClick}
      width="191"
      height="191"
      viewBox="0 0 191 191"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 -0.000106812L0 190.844H190.844V-0.000106812H0Z"
        fill="#ECECE7"
      />
      <path
        d="M148.429 129H65.4762L59.6485 105.31M24 32H41.6926L46.3074 50.8209M46.3074 50.8209H158L144.368 105.31H59.6485M46.3074 50.8209L59.6485 105.31"
        stroke="#0E0E10"
        strokeWidth="14"
      />
      <path
        d="M86 157C86 162.37 81.4548 167 75.5 167C69.5452 167 65 162.37 65 157C65 151.63 69.5452 147 75.5 147C81.4548 147 86 151.63 86 157Z"
        stroke="#0E0E10"
        strokeWidth="10"
      />
      <circle cx="134" cy="157" r="10" stroke="#0E0E10" strokeWidth="10" />
    </svg>
  );
};

Cart.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};
