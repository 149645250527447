const _temp0 = require("./banana.png");
const _temp1 = require("./chicken.png");
const _temp2 = require("./choco.png");
const _temp3 = require("./choco_c.png");
const _temp4 = require("./coffee.png");
const _temp5 = require("./fisch.png");
const _temp6 = require("./kakao.png");
const _temp7 = require("./kokos.png");
const _temp8 = require("./nuss.png");
const _temp9 = require("./phone.png");
const _temp10 = require("./schinken.png");
const _temp11 = require("./sugar.png");
const _temp12 = require("./wolle.png");
module.exports = {
  "banana": _temp0,
  "chicken": _temp1,
  "choco": _temp2,
  "choco_c": _temp3,
  "coffee": _temp4,
  "fisch": _temp5,
  "kakao": _temp6,
  "kokos": _temp7,
  "nuss": _temp8,
  "phone": _temp9,
  "schinken": _temp10,
  "sugar": _temp11,
  "wolle": _temp12
}