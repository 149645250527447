import PropTypes from "prop-types";
import classNames from "classnames";

export const Close = (props) => {
  return (
    <svg
      className={classNames({
        [props.className]: props.className,
        "cursor-pointer": true,
      })}
      onClick={props.onClick}
      width="191"
      height="192"
      viewBox="0 0 191 192"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0.00038147L0 190.844H190.844V0.00038147H0Z" fill="#0E0E10" />
      <rect
        x="33.5388"
        y="62.9819"
        width="40.0528"
        height="134.915"
        transform="rotate(-45 33.5388 62.9819)"
        fill="#ECECE7"
      />
      <rect
        x="61.8606"
        y="158.381"
        width="40.0528"
        height="134.915"
        transform="rotate(-135 61.8606 158.381)"
        fill="#ECECE7"
      />
    </svg>
  );
};

Close.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};
