export const Right = () => {
  return (
    <svg
      width="1730"
      height="1080"
      viewBox="0 0 1730 1080"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="m-auto flex"
    >
      <path
        d="M284.92 850.12C284.92 797.08 247.86 759 193.8 759H6.8V997H95.2V941.24H147.22L180.2 997H284.92L242.76 928.66C268.94 913.36 284.92 884.8 284.92 850.12ZM95.2 833.8H176.8C185.64 833.8 196.52 833.8 196.52 850.12C196.52 866.44 185.64 866.44 176.8 866.44H95.2V833.8ZM383.403 759H295.003V997H383.403V759ZM390.216 877.66C390.216 960.62 435.096 1000.4 539.816 1000.4C635.356 1000.4 684.656 966.74 688.396 891.26H597.956C594.556 914.04 577.216 925.6 539.816 925.6C487.796 925.6 478.616 903.16 478.616 877.66C478.616 852.5 487.456 830.4 539.816 830.4C577.216 830.4 594.556 841.62 597.956 864.06H688.396C684.656 788.92 635.016 755.6 539.816 755.6C435.096 755.6 390.216 795.04 390.216 877.66ZM926.553 759V840.6H787.153V759H698.753V997H787.153V915.4H926.553V997H1014.95V759H926.553ZM1317.46 759H1025.06V833.8H1127.06V997H1215.46V833.8H1317.46V759ZM1416.02 759H1327.62V997H1416.02V759ZM1566.65 915.06H1630.57C1627.17 931.04 1606.43 935.8 1572.43 935.8C1520.41 935.8 1511.23 909.96 1511.23 881.06C1511.23 852.5 1520.07 830.4 1572.43 830.4C1609.83 830.4 1627.17 841.62 1630.57 853.86H1721.01C1717.27 788.92 1667.63 755.6 1572.43 755.6C1474.51 755.6 1422.83 795.04 1422.83 877.66C1422.83 960.62 1474.51 1000.4 1562.23 1000.4C1612.21 1000.4 1643.49 986.12 1663.55 962.66L1670.01 997H1721.01V867.46H1566.65V915.06Z"
        fill="#0E0E10"
      />
      <path
        d="M284.92 504.12C284.92 451.08 247.86 413 193.8 413H6.8V651H95.2V595.24H147.22L180.2 651H284.92L242.76 582.66C268.94 567.36 284.92 538.8 284.92 504.12ZM95.2 487.8H176.8C185.64 487.8 196.52 487.8 196.52 504.12C196.52 520.44 185.64 520.44 176.8 520.44H95.2V487.8ZM383.403 413H295.003V651H383.403V413ZM390.216 531.66C390.216 614.62 435.096 654.4 539.816 654.4C635.356 654.4 684.656 620.74 688.396 545.26H597.956C594.556 568.04 577.216 579.6 539.816 579.6C487.796 579.6 478.616 557.16 478.616 531.66C478.616 506.5 487.456 484.4 539.816 484.4C577.216 484.4 594.556 495.62 597.956 518.06H688.396C684.656 442.92 635.016 409.6 539.816 409.6C435.096 409.6 390.216 449.04 390.216 531.66ZM926.553 413V494.6H787.153V413H698.753V651H787.153V569.4H926.553V651H1014.95V413H926.553ZM1317.46 413H1025.06V487.8H1127.06V651H1215.46V487.8H1317.46V413ZM1416.02 413H1327.62V651H1416.02V413ZM1566.65 569.06H1630.57C1627.17 585.04 1606.43 589.8 1572.43 589.8C1520.41 589.8 1511.23 563.96 1511.23 535.06C1511.23 506.5 1520.07 484.4 1572.43 484.4C1609.83 484.4 1627.17 495.62 1630.57 507.86H1721.01C1717.27 442.92 1667.63 409.6 1572.43 409.6C1474.51 409.6 1422.83 449.04 1422.83 531.66C1422.83 614.62 1474.51 654.4 1562.23 654.4C1612.21 654.4 1643.49 640.12 1663.55 616.66L1670.01 651H1721.01V521.46H1566.65V569.06Z"
        fill="#0E0E10"
      />
      <path
        d="M284.92 158.12C284.92 105.08 247.86 67 193.8 67H6.8V305H95.2V249.24H147.22L180.2 305H284.92L242.76 236.66C268.94 221.36 284.92 192.8 284.92 158.12ZM95.2 141.8H176.8C185.64 141.8 196.52 141.8 196.52 158.12C196.52 174.44 185.64 174.44 176.8 174.44H95.2V141.8ZM383.403 67H295.003V305H383.403V67ZM390.216 185.66C390.216 268.62 435.096 308.4 539.816 308.4C635.356 308.4 684.656 274.74 688.396 199.26H597.956C594.556 222.04 577.216 233.6 539.816 233.6C487.796 233.6 478.616 211.16 478.616 185.66C478.616 160.5 487.456 138.4 539.816 138.4C577.216 138.4 594.556 149.62 597.956 172.06H688.396C684.656 96.92 635.016 63.6 539.816 63.6C435.096 63.6 390.216 103.04 390.216 185.66ZM926.553 67V148.6H787.153V67H698.753V305H787.153V223.4H926.553V305H1014.95V67H926.553ZM1317.46 67H1025.06V141.8H1127.06V305H1215.46V141.8H1317.46V67ZM1416.02 67H1327.62V305H1416.02V67ZM1566.65 223.06H1630.57C1627.17 239.04 1606.43 243.8 1572.43 243.8C1520.41 243.8 1511.23 217.96 1511.23 189.06C1511.23 160.5 1520.07 138.4 1572.43 138.4C1609.83 138.4 1627.17 149.62 1630.57 161.86H1721.01C1717.27 96.92 1667.63 63.6 1572.43 63.6C1474.51 63.6 1422.83 103.04 1422.83 185.66C1422.83 268.62 1474.51 308.4 1562.23 308.4C1612.21 308.4 1643.49 294.12 1663.55 270.66L1670.01 305H1721.01V175.46H1566.65V223.06Z"
        fill="#0E0E10"
      />
    </svg>
  );
};
