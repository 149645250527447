import { UI } from "./ui/ui";
import { useDispatch, useSelector } from "react-redux";
import { setByValue } from "./itemsSlice";
import { setByValue as setCurrentItemByValue } from "./currentItemSlice";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

import * as itemImages from "./../../static/items/*.png";

import cartBackgroundImage from "./../../static/cart-background.png";
import { useEffect } from "react";

export const Cart = () => {
  const items = useSelector((state) => state.items.value);
  const final = useSelector((state) => state.final.value);
  const usedItems = items.filter((item) => item.used);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  let code = "";

  const handleQrScannerInput = (event) => {
    console.log("event.keyCode", event.keyCode);
    console.log("event.key", event.key);

    // No Ctrl+Shift+C and no Enter and no F11.
    if (
      event.keyCode !== 13 &&
      event.keyCode !== 17 &&
      event.keyCode !== 16 &&
      event.keyCode !== 67 &&
      event.keyCode !== 122
    ) {
      event.preventDefault();
      code = code.toString() + event.key.toString();
    }

    // Enter at the end of input.
    if (event.keyCode === 13) {
      event.preventDefault();

      const item = items.filter((i) => i.code === code);

      console.log(code);
      console.log(item);

      if (item.length > 0) {
        dispatch(setCurrentItemByValue(item[0]));
        navigate("/spielen/present");
      } else {
        code = "";
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleQrScannerInput);

    if (final) {
      setTimeout(() => {
        navigate("/fazit");
      }, 1000 * 10);
    }

    // Return a clean-up function.
    return () => {
      window.removeEventListener("keydown", handleQrScannerInput);
    };
  }, []);

  return (
    <div className="relative h-full min-h-screen bg-freiburg-red pb-40">
      {usedItems.length < 12 ? <UI items={["close", "back"]} /> : null}
      {usedItems.length === 12 ? (
        <h1 className="pt-10 pb-8 text-center font-Monument text-[93px] font-bold">
          Dein Einkaufswagen ist voll!
        </h1>
      ) : (
        <h1 className="mr-[190px] pt-10 pb-8 text-center font-Monument text-[110px] font-bold">
          Dein Einkaufswagen
        </h1>
      )}

      <div className="mx-10 grid grid-cols-6 gap-4">
        {Array.from(Array(12).keys()).map((item, index) => (
          <div
            className="flex aspect-square items-center justify-center bg-contain bg-center bg-no-repeat"
            style={{ backgroundImage: `url(${cartBackgroundImage})` }}
            key={index}
          >
            {usedItems[index] ? (
              <img
                className={classNames({
                  "h-auto max-w-[65%]": true,
                  "ml-[40px] max-w-[100%]": usedItems[index].image === "phone",
                })}
                src={itemImages[usedItems[index].image]}
              />
            ) : null}
          </div>
        ))}
      </div>

      {usedItems.length < 12 ? (
        <div
          onClick={() => {
            navigate("/");
          }}
          className="absolute absolute bottom-0 left-0 flex h-40 w-full items-center justify-center bg-black text-freiburg-white"
        >
          <span className="text-center font-Monument text-[58px] font-extrabold uppercase">
            Nächster Scan
          </span>
        </div>
      ) : null}
    </div>
  );
};
