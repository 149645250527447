export const Wrong = () => {
  return (
    <svg
      width="1730"
      height="1080"
      viewBox="0 0 1730 1080"
      fill="#D5654D"
      xmlns="http://www.w3.org/2000/svg"
      className="m-auto flex"
    >
      <path
        d="M253.84 834.68V761.2H6.68V995H93.52V933.21H233.8V859.73H93.52V834.68H253.84ZM450.626 995H546.15L449.29 761.2H320.366L223.506 995H319.03L335.062 952.916H434.594L450.626 995ZM357.774 892.796L384.828 821.32L411.882 892.796H357.774ZM639.858 921.52V761.2H553.018V995H793.498V921.52H639.858ZM800.178 912.502C800.178 981.974 853.618 998.34 949.476 998.34C1045.33 998.34 1098.77 981.974 1098.77 917.512C1098.77 856.39 1041.66 850.044 949.476 846.37C898.708 844.366 890.358 844.7 890.358 835.682C890.358 826.664 898.708 824.66 939.456 824.66C981.874 824.66 1001.91 827.332 1001.91 839.69H1088.75C1088.75 779.904 1037.32 757.86 946.136 757.86C853.952 757.86 803.518 779.904 803.518 831.34C803.518 885.782 843.932 903.15 949.476 906.49C1004.25 908.16 1011.93 909.496 1011.93 919.516C1011.93 928.2 1004.25 931.54 952.816 931.54C897.706 931.54 887.018 923.858 887.018 912.502H800.178ZM1102.21 877.766C1102.21 959.262 1146.3 998.34 1249.17 998.34C1343.03 998.34 1391.46 965.274 1395.13 891.126H1306.29C1302.95 913.504 1285.91 924.86 1249.17 924.86C1198.07 924.86 1189.05 902.816 1189.05 877.766C1189.05 853.05 1197.74 831.34 1249.17 831.34C1285.91 831.34 1302.95 842.362 1306.29 864.406H1395.13C1391.46 790.592 1342.69 757.86 1249.17 757.86C1146.3 757.86 1102.21 796.604 1102.21 877.766ZM1629.09 761.2V841.36H1492.15V761.2H1405.31V995H1492.15V914.84H1629.09V995H1715.93V761.2H1629.09Z"
        fill="#0E0E10"
      />
      <path
        d="M253.84 488.68V415.2H6.68V649H93.52V587.21H233.8V513.73H93.52V488.68H253.84ZM450.626 649H546.15L449.29 415.2H320.366L223.506 649H319.03L335.062 606.916H434.594L450.626 649ZM357.774 546.796L384.828 475.32L411.882 546.796H357.774ZM639.858 575.52V415.2H553.018V649H793.498V575.52H639.858ZM800.178 566.502C800.178 635.974 853.618 652.34 949.476 652.34C1045.33 652.34 1098.77 635.974 1098.77 571.512C1098.77 510.39 1041.66 504.044 949.476 500.37C898.708 498.366 890.358 498.7 890.358 489.682C890.358 480.664 898.708 478.66 939.456 478.66C981.874 478.66 1001.91 481.332 1001.91 493.69H1088.75C1088.75 433.904 1037.32 411.86 946.136 411.86C853.952 411.86 803.518 433.904 803.518 485.34C803.518 539.782 843.932 557.15 949.476 560.49C1004.25 562.16 1011.93 563.496 1011.93 573.516C1011.93 582.2 1004.25 585.54 952.816 585.54C897.706 585.54 887.018 577.858 887.018 566.502H800.178ZM1102.21 531.766C1102.21 613.262 1146.3 652.34 1249.17 652.34C1343.03 652.34 1391.46 619.274 1395.13 545.126H1306.29C1302.95 567.504 1285.91 578.86 1249.17 578.86C1198.07 578.86 1189.05 556.816 1189.05 531.766C1189.05 507.05 1197.74 485.34 1249.17 485.34C1285.91 485.34 1302.95 496.362 1306.29 518.406H1395.13C1391.46 444.592 1342.69 411.86 1249.17 411.86C1146.3 411.86 1102.21 450.604 1102.21 531.766ZM1629.09 415.2V495.36H1492.15V415.2H1405.31V649H1492.15V568.84H1629.09V649H1715.93V415.2H1629.09Z"
        fill="#0E0E10"
      />
      <path
        d="M253.84 142.68V69.2H6.68V303H93.52V241.21H233.8V167.73H93.52V142.68H253.84ZM450.626 303H546.15L449.29 69.2H320.366L223.506 303H319.03L335.062 260.916H434.594L450.626 303ZM357.774 200.796L384.828 129.32L411.882 200.796H357.774ZM639.858 229.52V69.2H553.018V303H793.498V229.52H639.858ZM800.178 220.502C800.178 289.974 853.618 306.34 949.476 306.34C1045.33 306.34 1098.77 289.974 1098.77 225.512C1098.77 164.39 1041.66 158.044 949.476 154.37C898.708 152.366 890.358 152.7 890.358 143.682C890.358 134.664 898.708 132.66 939.456 132.66C981.874 132.66 1001.91 135.332 1001.91 147.69H1088.75C1088.75 87.904 1037.32 65.86 946.136 65.86C853.952 65.86 803.518 87.904 803.518 139.34C803.518 193.782 843.932 211.15 949.476 214.49C1004.25 216.16 1011.93 217.496 1011.93 227.516C1011.93 236.2 1004.25 239.54 952.816 239.54C897.706 239.54 887.018 231.858 887.018 220.502H800.178ZM1102.21 185.766C1102.21 267.262 1146.3 306.34 1249.17 306.34C1343.03 306.34 1391.46 273.274 1395.13 199.126H1306.29C1302.95 221.504 1285.91 232.86 1249.17 232.86C1198.07 232.86 1189.05 210.816 1189.05 185.766C1189.05 161.05 1197.74 139.34 1249.17 139.34C1285.91 139.34 1302.95 150.362 1306.29 172.406H1395.13C1391.46 98.592 1342.69 65.86 1249.17 65.86C1146.3 65.86 1102.21 104.604 1102.21 185.766ZM1629.09 69.2V149.36H1492.15V69.2H1405.31V303H1492.15V222.84H1629.09V303H1715.93V69.2H1629.09Z"
        fill="#0E0E10"
      />
    </svg>
  );
};
