import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export const Fazit = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/idle");
    }, 1000 * 5);
  }, []);

  return (
    <div className="flex h-full min-h-screen items-center justify-center bg-black">
      <h1 className="mx-20 text-center font-Monument text-9xl font-bold leading-[1.1em] text-freiburg-white">
        Dein Einkauf ist die Chance für eine bessere Zukunft: Informiere dich
        und kaufe nachhaltige Produkte.
      </h1>
    </div>
  );
};
