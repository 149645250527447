import { useSelector, useDispatch } from "react-redux";
import { Redirect, useNavigate } from "react-router-dom";
import classNames from "classnames";

import * as itemImages from "./../../static/items/*.png";
import * as backgroundImages from "./../../static/backgrounds/*.svg";
import * as backgroundVideos from "url:./../../static/backgrounds/*.mp4";

import { UI } from "./ui/ui";
import { useEffect } from "react";
import { setMode } from "./modeSlice";
import { setAnswer } from "./answerSlice";

export const Presentation = () => {
  const item = useSelector((state) => state.currentItem.value);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  console.log(item);

  useEffect(() => {
    dispatch(setMode(null));
    dispatch(setAnswer(null));
    if (!item) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <UI items={["close", "cart", "play"]} />
      <div
        className={`relative h-full min-h-screen w-full overflow-y-hidden bg-freiburg-red ${item.background}`}
      >
        {/*<div
          className="absolute top-12 left-12 bottom-12 right-64 bg-contain bg-center bg-no-repeat"
          style={{
            backgroundImage: `url('${backgroundImages[item.background_text]}')`,
          }}
        />*/}

        <div className="relative flex h-screen items-center justify-center overflow-hidden">
          <video
            autoPlay
            loop
            muted
            className="absolute z-10 h-full w-full object-cover"
          >
            <source
              src={backgroundVideos[item.background_text]}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>

        <img
          src={itemImages[item.image]}
          className={classNames({
            "absolute top-1/2 left-1/2 z-20 -ml-[192px] h-full w-full -translate-x-1/2 -translate-y-1/2 object-contain object-top": true,
            "-ml-[0px] -mr-[192px]":
              item.image === "sugar" ||
              item.image === "kakao" ||
              item.image === "wolle" ||
              item.image === "fisch" ||
              item.image === "coffee",
            "max-w-[75%] object-center": item.image === "chicken",
          })}
        />
        <svg
          className={classNames({
            "absolute top-2/3 left-2/3 z-30 h-auto max-w-[40rem] -translate-x-1/2 -translate-y-1/2": true,
            "-mt-[50px] -ml-[30px]": item.image === "choco",
            "-mt-[105px]": item.image === "banana",
            "left-auto right-2/3 -mt-[180px] -mr-[160px] translate-x-[0px]":
              item.image === "sugar",
            "-mt-[150px] -ml-[240px]": item.image === "phone",
            "left-auto right-2/3 -mt-[35px] -mr-[340px] translate-x-[0px]":
              item.image === "kakao",
            "left-auto right-2/3 -mt-[30px] -mr-[215px] translate-x-[0px]":
              item.image === "coffee",
            "-mt-[320px] -ml-[60px]": item.image === "chicken",
            "left-auto right-2/3 mt-[13px] -mr-[260px] translate-x-[0px]":
              item.image === "wolle",
            "left-auto right-2/3 -mt-[10px] -mr-[360px] translate-x-[0px]":
              item.image === "fisch",
            "-mt-[301px]": item.image === "kokos",
            "-mt-[302px] -ml-[20px]": item.image === "schinken",
            "-mt-[300px]": item.image === "nuss",
          })}
          width="100%"
          height="100%"
          viewBox="0 0 640 639"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M574.935 399.301L572.303 400.654L573.62 403.304L603.532 463.489L537.71 474.16L534.788 474.634L535.222 477.562L545.078 544.045L479.174 533.855L476.249 533.403L475.757 536.321L464.585 602.587L405.058 572.539L402.416 571.205L401.047 573.828L369.947 633.402L322.618 586.419L320.518 584.334L318.405 586.406L270.417 633.457L239.921 574.154L238.568 571.523L235.918 572.84L175.74 602.752L165.061 536.929L164.588 534.008L161.66 534.442L95.1841 544.298L105.374 478.394L105.827 475.469L102.908 474.977L36.6353 463.805L66.6903 404.278L68.0243 401.636L65.4007 400.267L5.82717 369.166L52.8026 321.838L54.8873 319.738L52.8157 317.625L5.77177 269.637L65.0748 239.141L67.7064 237.788L66.3892 235.138L36.4768 174.96L102.3 164.281L105.221 163.808L104.787 160.88L94.9315 94.4041L160.835 104.594L163.76 105.047L164.252 102.128L175.418 35.8553L234.951 65.9104L237.593 67.2441L238.963 64.6207L270.063 5.04714L317.391 52.0226L319.491 54.1073L321.604 52.0356L369.591 4.99201L400.081 64.2945L401.434 66.9264L404.084 65.6093L464.269 35.6968L474.94 101.519L475.414 104.441L478.342 104.007L544.825 94.1514L534.635 160.055L534.183 162.98L537.101 163.472L603.367 174.644L573.319 234.171L571.985 236.813L574.608 238.182L634.182 269.283L587.2 316.611L585.114 318.711L587.186 320.825L634.237 368.812L574.935 399.301Z"
            fill="#DDAF27"
            stroke="#0E0E10"
            strokeWidth="6"
          />
          <foreignObject className="relative h-full w-full">
            <div
              className={classNames({
                "mx-auto flex h-[640px] max-w-[77%] flex-col items-center justify-center": true,
                "mt-8": item.image === "nuss",
                "-mt-4": item.image === "banana",
                "mt-2": item.image === "chicken",
              })}
            >
              <div className="max-w-[95%] -rotate-[4deg] text-center font-Monument text-[42px] font-extrabold uppercase leading-tight">
                {item.headline}
              </div>
              <div
                className={classNames({
                  "mt-8 -rotate-[4deg] px-2 text-center text-[34px] font-bold leading-[40px]": true,
                  "px-8": item.image === "kakao" || item.image === "nuss",
                })}
              >
                {item.subline}
              </div>
            </div>
          </foreignObject>
        </svg>
      </div>
    </>
  );
};
