import { createSlice } from "@reduxjs/toolkit";

export const currentItemSlice = createSlice({
  name: "currentItem",
  initialState: {
    value: null,
  },
  reducers: {
    setByValue: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setByValue } = currentItemSlice.actions;

export default currentItemSlice.reducer;
