import PropTypes from "prop-types";

export const RightHand = (props) => {
  return (
    <svg
      width="744"
      height="784"
      viewBox="0 0 744 784"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M180.802 321.165L326.915 741.223L419.046 712.715C472.636 696.215 517.873 659.849 545.427 611.117L687.482 567.162C687.482 567.162 715.342 517.019 684.746 422.729C654.025 327.93 595.931 300.91 595.931 300.91L489.876 331.249C480.543 324.101 470.845 317.441 460.819 311.294C448.931 304.165 398.408 276.475 386.602 239.989C374.659 202.644 357.937 73.0879 319.932 75.424C234.431 80.717 257.994 174.289 274.545 235.809C277.444 246.368 276.941 257.575 273.106 267.846C269.27 278.117 262.298 286.932 253.173 293.047C240.493 301.047 226.749 307.227 212.347 311.404C207.016 313.054 204.148 314.424 203.38 315.594L180.802 321.165Z"
        fill="#46877F"
        stroke="#0E0E10"
        strokeWidth="12"
        strokeMiterlimit="10"
      />
      <path
        d="M491.762 332.339C526.085 359.665 551.338 396.682 564.231 438.567Z"
        fill="#46877F"
      />
      <path
        d="M491.762 332.339C526.085 359.665 551.338 396.682 564.231 438.567"
        stroke="#0E0E10"
        strokeWidth="12"
        strokeMiterlimit="10"
      />
      <path
        d="M193.975 724.206L193.966 724.177C184.897 694.867 201.386 663.73 230.797 654.63L294.064 635.054C323.474 625.953 354.668 642.336 363.737 671.645L363.746 671.675C372.815 700.984 356.325 732.121 326.915 741.222L263.648 760.798C234.238 769.898 203.044 753.516 193.975 724.206Z"
        fill="#46877F"
        stroke="#0E0E10"
        strokeWidth="12"
        strokeMiterlimit="10"
      />
      <path
        d="M140.684 624.364L140.674 624.335C131.605 595.026 148.095 563.888 177.505 554.788L280.21 523.009C309.62 513.908 340.814 530.291 349.883 559.6L349.892 559.63C358.962 588.939 342.471 620.077 313.061 629.177L210.356 660.956C180.946 670.057 149.753 653.674 140.684 624.364Z"
        fill="#46877F"
        stroke="#0E0E10"
        strokeWidth="12"
        strokeMiterlimit="10"
      />
      <path
        d="M107.841 518.225L107.832 518.195C98.7629 488.886 115.253 457.748 144.663 448.648L262.536 412.175C291.947 403.075 323.14 419.458 332.209 448.767L332.219 448.797C341.288 478.106 324.798 509.243 295.388 518.344L177.514 554.817C148.104 563.917 116.91 547.534 107.841 518.225Z"
        fill="#46877F"
        stroke="#0E0E10"
        strokeWidth="12"
        strokeMiterlimit="10"
      />
      <path
        d="M74.9607 412.065L74.9516 412.035C65.8825 382.726 82.3723 351.589 111.783 342.488L249.92 299.745C279.33 290.645 310.524 307.028 319.593 336.337L319.602 336.366C328.671 365.676 312.182 396.813 282.771 405.914L144.634 448.657C115.223 457.757 84.0298 441.374 74.9607 412.065Z"
        fill="#46877F"
        stroke="#0E0E10"
        strokeWidth="12"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

RightHand.propTypes = {
  className: PropTypes.string,
};
