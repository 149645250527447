import { configureStore } from "@reduxjs/toolkit";

import itemsReducer from "./game/itemsSlice";
import currentItemReducer from "./game/currentItemSlice";
import modeReducer from "./game/modeSlice";
import finalReducer from "./game/finalSlice";
import answerReducer from "./game/answerSlice";

export default configureStore({
  reducer: {
    items: itemsReducer,
    currentItem: currentItemReducer,
    mode: modeReducer,
    final: finalReducer,
    answer: answerReducer,
  },
});
