import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import classNames from "classnames";

import reactionBackgroundImageRight from "./../../static/hand-reaction-background.svg";
import reactionBackgroundImageWrong from "./../../static/hand-reaction-background-wrong.svg";
import { setByValue as setCurrentItemByValue } from "./currentItemSlice";
import { UI } from "./ui/ui";
import * as backgroundImages from "./../../static/backgrounds_quiz/*.png";
import * as backgroundImagesDescription from "./../../static/backgrounds_quiz_description/*.png";
import { Right } from "./reactions/right";
import { Wrong } from "./reactions/wrong";
import { RightHand } from "./reactions/right-hand";
import { WrongHand } from "./reactions/wrong-hand";
import { setByValue } from "./itemsSlice";
import { setMode } from "./modeSlice";
import { setAnswer } from "./answerSlice";

export const Quiz = () => {
  //const [mode, setMode] = useState(null);
  const answer = useSelector((state) => state.answer.value);
  const mode = useSelector((state) => state.mode.value);
  const item = useSelector((state) => state.currentItem.value);
  const items = useSelector((state) => state.items.value);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let code = "";

  const handleQrScannerInput = (event) => {
    console.log("event.keyCode", event.keyCode);
    console.log("event.key", event.key);

    // No Ctrl+Shift+C and no Enter and no F11.
    if (
      event.keyCode !== 13 &&
      event.keyCode !== 17 &&
      event.keyCode !== 16 &&
      event.keyCode !== 67 &&
      event.keyCode !== 122
    ) {
      event.preventDefault();
      code = code.toString() + event.key.toString();
    }

    // Enter at the end of input.
    if (event.keyCode === 13) {
      event.preventDefault();

      const item = items.filter((i) => i.code === code);

      console.log(code);
      console.log(item);

      if (item.length > 0) {
        dispatch(setCurrentItemByValue(item[0]));
        navigate("/spielen/present");
      } else {
        code = "";
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleQrScannerInput);

    // Return a clean-up function.
    return () => {
      window.removeEventListener("keydown", handleQrScannerInput);
    };
  }, []);

  useEffect(() => {
    if (mode === "reaction") {
      setTimeout(() => {
        dispatch(setMode("description"));
        // Mark item as used.
        dispatch(
          setByValue(
            items.map((questionItem) => {
              if (item.code === questionItem.code) {
                return { ...questionItem, used: true };
              }

              return questionItem;
            })
          )
        );
      }, 1750);
    }
  }, [mode]);

  let UiItems = ["close"];
  switch (mode) {
    case "reaction":
      UiItems = [];
      break;
    case "description":
      UiItems = ["close", "cart", "repeat", "back"];
  }

  const next = (
    <div
      onClick={() => {
        dispatch(setCurrentItemByValue(null));
        navigate("/");
      }}
      className="absolute absolute bottom-0 left-0 flex h-40 w-full items-center justify-center bg-black text-freiburg-white"
    >
      <span className="-ml-[10rem] text-center font-Monument text-[58px] font-extrabold uppercase">
        Nächster Scan
      </span>
    </div>
  );

  return (
    <>
      <UI items={UiItems} />
      <Transition
        show={mode === null}
        enter="transition-opacity duration-150"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="h-full"
      >
        <div
          className={`relative h-full min-h-screen w-full overflow-y-hidden bg-freiburg-red ${item.background}`}
        >
          <img
            src={backgroundImages[item.background_quiz]}
            className="absolute top-0 left-0 h-full w-full object-cover object-top"
          />
          <h1
            className={classNames({
              "mx-auto mt-60 mb-10 block max-w-[95%] text-center font-Monument text-7xl font-bold leading-[1.25em]": true,
            })}
          >
            {item.question}
          </h1>

          <div className="absolute top-[50vh] left-[7.5%] z-20 mx-auto mt-0 flex w-full max-w-[85%] flex-row justify-between">
            <svg
              onClick={() => {
                dispatch(setAnswer("A"));
                dispatch(setMode("reaction"));
              }}
              className="h-auto max-w-[45rem] cursor-pointer text-freiburg-white hover:text-freiburg-yellow"
              width="100%"
              height="100%"
              viewBox="0 0 749 424"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.0164 233.107V233.106V193.884C3.19207 170.2 13.4857 151.191 35.7172 135.177L35.7207 135.175C42.5552 130.236 47.6845 121.728 51.5895 112.237C55.5263 102.668 58.3779 91.6999 60.4341 81.3964C62.4936 71.0769 63.7729 61.3334 64.5376 54.1758C64.9202 50.5944 65.1747 47.6542 65.3337 45.6052C65.3442 45.4704 65.3542 45.3394 65.3639 45.2124H683.559C683.569 45.3392 683.579 45.47 683.589 45.6046C683.748 47.6536 684.002 50.5939 684.384 54.1753C685.147 61.3329 686.425 71.0766 688.484 81.3961C690.539 91.6997 693.39 102.668 697.328 112.237C701.233 121.729 706.365 130.237 713.204 135.175C735.425 151.235 745.732 170.204 745.891 193.884C745.891 193.887 745.891 193.89 745.891 193.893V272.32C745.891 272.323 745.891 272.326 745.891 272.329C745.732 296.011 735.423 315.02 713.206 331.036L713.203 331.038C706.369 335.976 701.239 344.484 697.334 353.975C693.398 363.544 690.546 374.512 688.49 384.816C686.43 395.135 685.151 404.879 684.386 412.037C684.004 415.618 683.749 418.558 683.59 420.607C683.58 420.742 683.57 420.873 683.56 421H65.3481C65.3384 420.873 65.3284 420.742 65.318 420.608C65.1594 418.559 64.9055 415.618 64.5235 412.037C63.7601 404.88 62.4824 395.136 60.4239 384.816C58.3687 374.513 55.5176 363.544 51.5799 353.975C47.674 344.483 42.5425 335.975 35.7035 331.037L35.7004 331.035C13.4055 314.98 3.17584 296.015 3 272.329L3.0164 233.107Z"
                fill="#ECECE7"
                stroke="black"
                strokeWidth="6"
                className="fill-current"
              />
              <rect
                x="22.213"
                width="107.071"
                height="107.071"
                fill="#0E0E10"
              />
              <path
                d="M89.9204 77.8481H110.512L89.6324 27.4481H61.8404L40.9604 77.8481H61.5524L65.0084 68.7761H86.4644L89.9204 77.8481ZM69.9044 55.8161L75.7364 40.4081L81.5684 55.8161H69.9044Z"
                fill="#ECECE7"
              />
              <foreignObject className="relative h-full w-full">
                <div className="mx-auto mt-[42px] flex h-[383px] max-w-[90%] flex-col items-center justify-center">
                  <div className="text-center text-[38px] font-bold leading-[45px] text-black">
                    {item.A}
                  </div>
                </div>
              </foreignObject>
            </svg>

            <svg
              onClick={() => {
                dispatch(setAnswer("B"));
                dispatch(setMode("reaction"));
              }}
              className="h-auto max-w-[45rem] cursor-pointer text-freiburg-white hover:text-freiburg-yellow"
              width="100%"
              height="100%"
              viewBox="0 0 750 424"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.63585 233.107V233.106V193.884C3.81152 170.2 14.1051 151.191 36.3366 135.177L36.3401 135.175C43.1747 130.236 48.304 121.728 52.2089 112.237C56.1458 102.668 58.9973 91.6999 61.0536 81.3964C63.113 71.0769 64.3923 61.3334 65.157 54.1758C65.5397 50.5944 65.7941 47.6542 65.9532 45.6052C65.9636 45.4704 65.9737 45.3394 65.9833 45.2124H684.179C684.188 45.3392 684.198 45.47 684.209 45.6046C684.368 47.6536 684.621 50.5939 685.003 54.1753C685.767 61.3329 687.045 71.0766 689.103 81.3961C691.158 91.6997 694.009 102.668 697.947 112.237C701.853 121.729 706.984 130.237 713.823 135.175C736.045 151.235 746.351 170.204 746.51 193.884C746.51 193.887 746.511 193.89 746.511 193.893V272.32C746.511 272.323 746.51 272.326 746.51 272.329C746.351 296.011 736.043 315.02 713.825 331.036L713.823 331.038C706.988 335.976 701.859 344.484 697.954 353.975C694.017 363.544 691.165 374.512 689.109 384.816C687.05 395.135 685.77 404.879 685.006 412.037C684.623 415.618 684.369 418.558 684.21 420.607C684.199 420.742 684.189 420.873 684.18 421H65.9675C65.9579 420.873 65.9479 420.742 65.9375 420.608C65.7789 418.559 65.525 415.618 65.143 412.037C64.3796 404.88 63.1018 395.136 61.0434 384.816C58.9881 374.513 56.1371 363.544 52.1994 353.975C48.2935 344.483 43.162 335.975 36.3229 331.037L36.3198 331.035C14.0249 314.98 3.79529 296.015 3.61945 272.329L3.63585 233.107Z"
                fill="#ECECE7"
                stroke="black"
                strokeWidth="6"
                className="fill-current"
              />
              <rect
                x="22.8324"
                width="107.071"
                height="107.071"
                fill="#0E0E10"
              />
              <path
                d="M100.4 49.6961C102.848 46.8161 104.36 43.5041 104.36 40.2641C104.36 32.6321 100.832 27.4481 89.384 27.4481H45.464V77.8481H91.544C102.992 77.8481 107.96 71.2241 107.96 62.9441C107.96 57.4721 104.792 52.8641 100.4 49.6961ZM64.184 39.6881H81.464C83.336 39.6881 85.64 39.6881 85.64 42.4241C85.64 45.1601 83.336 45.1601 81.464 45.1601H64.184V39.6881ZM85.064 64.1681H64.184V58.8401H85.064C86.936 58.8401 89.24 58.8401 89.24 61.5041C89.24 64.1681 86.936 64.1681 85.064 64.1681Z"
                fill="#ECECE7"
              />
              <foreignObject className="relative h-full w-full">
                <div className="mx-auto mt-[42px] flex h-[383px] max-w-[90%] flex-col items-center justify-center">
                  <div className="text-center text-[38px] font-bold leading-[45px] text-black">
                    {item.B}
                  </div>
                </div>
              </foreignObject>
            </svg>
          </div>
        </div>
      </Transition>

      {mode === "reaction" && item.correct.includes(answer) ? (
        <div className="h-full min-h-screen w-full bg-freiburg-yellow">
          <img
            className="absolute top-[80%] left-[83%] bottom-0 right-0 h-auto h-full w-full max-w-[45rem] -translate-x-3/4 -translate-y-3/4"
            src={reactionBackgroundImageRight}
          />
          <div className="absolute top-3/4 left-3/4 -translate-x-3/4 -translate-y-3/4">
            <RightHand className="h-auto max-w-[50rem] transform animate-[bounce_1s_ease]" />
          </div>
          <Right />
        </div>
      ) : null}

      {mode === "reaction" && !item.correct.includes(answer) ? (
        <div className="h-full min-h-screen w-full bg-freiburg-red">
          <img
            className="absolute top-[65%] left-[83%] bottom-0 right-0 h-auto h-full w-full max-w-[45rem] -translate-x-3/4 -translate-y-3/4"
            src={reactionBackgroundImageWrong}
          />
          <div className="absolute top-3/4 left-3/4 -translate-x-3/4 -translate-y-3/4">
            <WrongHand className="h-auto max-w-[50rem] transform animate-[bounce_1s_ease]" />
          </div>
          <Wrong />
        </div>
      ) : null}

      <Transition
        show={mode === "description" && item.correct.includes(answer)}
        enter="transition-opacity duration-150"
        enterFrom="opacity-50"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-50"
        className="h-full min-h-screen"
      >
        <div className="flex h-full min-h-screen w-full flex-col items-center justify-center bg-freiburg-yellow">
          <div
            className="absolute top-0 left-0 h-full w-full bg-cover"
            style={{
              backgroundImage: `url(${
                backgroundImagesDescription[item.image]
              })`,
            }}
          ></div>
          <svg
            className="relative z-10 mx-auto ml-[10rem] -mt-[10rem] h-auto max-w-[88rem]"
            width="100%"
            height="100%"
            viewBox="0 0 1391 709"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.03046 354.501V354.5V281.653C3.36084 236.74 22.9635 200.71 64.8258 170.554L64.8294 170.552C76.9373 161.803 86.2351 146.541 93.4266 129.061C100.65 111.504 105.906 91.3132 109.708 72.2635C113.513 53.1979 115.879 35.1845 117.293 21.9436C118.001 15.3205 118.471 9.88521 118.765 6.1016C118.858 4.89487 118.934 3.85599 118.994 3H1271.8C1271.86 3.85588 1271.93 4.89455 1272.03 6.10099C1272.32 9.88462 1272.79 15.32 1273.49 21.9431C1274.91 35.184 1277.27 53.1976 1281.07 72.2633C1284.87 91.3131 1290.13 111.504 1297.35 129.061C1304.55 146.542 1313.85 161.804 1325.96 170.552C1367.8 200.787 1387.43 236.739 1387.73 281.644C1387.73 281.65 1387.73 281.656 1387.73 281.662V427.338C1387.73 427.344 1387.73 427.35 1387.73 427.356C1387.43 472.264 1367.8 508.292 1325.97 538.446L1325.96 538.448C1313.85 547.197 1304.56 562.459 1297.37 579.939C1290.14 597.496 1284.89 617.687 1281.08 636.736C1277.28 655.802 1274.91 673.815 1273.5 687.056C1272.79 693.679 1272.32 699.115 1272.03 702.898C1271.93 704.105 1271.86 705.144 1271.8 706H118.964C118.904 705.144 118.829 704.105 118.736 702.899C118.443 699.115 117.973 693.68 117.267 687.057C115.855 673.816 113.492 655.802 109.689 636.737C105.889 617.687 100.634 597.496 93.4089 579.939C86.2158 562.458 76.914 547.196 64.7981 538.448L64.795 538.445C22.8131 508.214 3.33055 472.263 3 427.348L3.03046 354.501Z"
              fill="#ECECE7"
              stroke="black"
              strokeWidth="6"
            />
            <foreignObject className="relative h-full w-full">
              <div className="flex h-[709px] flex-col items-center justify-center">
                <div className="px-40 text-left text-5xl font-bold leading-snug text-black">
                  {answer === "A" ? item.description_a : item.description_b}
                </div>
              </div>
            </foreignObject>
          </svg>
          {next}
        </div>
      </Transition>
      <Transition
        show={mode === "description" && !item.correct.includes(answer)}
        enter="transition-opacity duration-150"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="h-full min-h-screen"
      >
        <div className="flex h-full min-h-screen w-full flex-col items-center justify-center bg-freiburg-red">
          <div
            className="absolute top-0 left-0 h-full w-full bg-cover"
            style={{
              backgroundImage: `url(${
                backgroundImagesDescription[item.image]
              })`,
            }}
          ></div>
          <svg
            className="relative z-10 mx-auto ml-[10rem] -mt-[10rem] h-auto max-w-[92rem]"
            width="100%"
            height="100%"
            viewBox="0 0 1391 709"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.03046 354.501V354.5V281.653C3.36084 236.74 22.9635 200.71 64.8258 170.554L64.8294 170.552C76.9373 161.803 86.2351 146.541 93.4266 129.061C100.65 111.504 105.906 91.3132 109.708 72.2635C113.513 53.1979 115.879 35.1845 117.293 21.9436C118.001 15.3205 118.471 9.88521 118.765 6.1016C118.858 4.89487 118.934 3.85599 118.994 3H1271.8C1271.86 3.85588 1271.93 4.89455 1272.03 6.10099C1272.32 9.88462 1272.79 15.32 1273.49 21.9431C1274.91 35.184 1277.27 53.1976 1281.07 72.2633C1284.87 91.3131 1290.13 111.504 1297.35 129.061C1304.55 146.542 1313.85 161.804 1325.96 170.552C1367.8 200.787 1387.43 236.739 1387.73 281.644C1387.73 281.65 1387.73 281.656 1387.73 281.662V427.338C1387.73 427.344 1387.73 427.35 1387.73 427.356C1387.43 472.264 1367.8 508.292 1325.97 538.446L1325.96 538.448C1313.85 547.197 1304.56 562.459 1297.37 579.939C1290.14 597.496 1284.89 617.687 1281.08 636.736C1277.28 655.802 1274.91 673.815 1273.5 687.056C1272.79 693.679 1272.32 699.115 1272.03 702.898C1271.93 704.105 1271.86 705.144 1271.8 706H118.964C118.904 705.144 118.829 704.105 118.736 702.899C118.443 699.115 117.973 693.68 117.267 687.057C115.855 673.816 113.492 655.802 109.689 636.737C105.889 617.687 100.634 597.496 93.4089 579.939C86.2158 562.458 76.914 547.196 64.7981 538.448L64.795 538.445C22.8131 508.214 3.33055 472.263 3 427.348L3.03046 354.501Z"
              fill="#ECECE7"
              stroke="black"
              strokeWidth="6"
            />
            <foreignObject className="relative h-full w-full">
              <div className="flex h-[709px] flex-col items-center justify-center">
                <div className="px-40 text-left text-5xl font-bold leading-snug text-black">
                  {answer === "A" ? item.description_a : item.description_b}
                </div>
              </div>
            </foreignObject>
          </svg>
          {next}
        </div>
      </Transition>
    </>
  );
};
